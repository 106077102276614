import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useAreasApi} from '../../../../apiHooks'
import {useAreasStore} from '../../../../store/hooks'
import {IAction, IHeadCell} from '../../../../interfaces/common/table'
import {ModalType} from '../../../../constants/modalType'
import {AdminLink, generateLink} from '../../../../utils'
import {useModal} from '../../../shared/modal/useModal.vm'
import {createTableRows} from '../../../shared/customTables/utils/createTableRows'
import {ReactComponent as DeleteIcon} from '../../../../assets/icon/delete.svg';
import {ReactComponent as EditIcon} from '../../../../assets/icon/edit.svg';
import theme from '../../../../theme/theme'

export const useAreas = () => {
    const navigate = useNavigate();
    const areasStore = useAreasStore()
    const {isAreasLoaded, areas} = areasStore
    const {getAreas, deleteArea} = useAreasApi()
    const [actionId, setActionId] = useState<number | null>(null);
    const {handleModal, isModalOpened, closeModal, modalType} = useModal();

    useEffect(() => {
        if (!isAreasLoaded) {
            if (areas === null) {
                void getAreas()
            } else {
                void getAreas(false)
            }
        }
    }, [isAreasLoaded, areas, getAreas])

    useEffect(() => {
        return () => areasStore.resetAreas();
    }, [areasStore])

    const deleteHandler = (id: number | string) => {
        handleModal(ModalType.DELETE_MODAL);
        setActionId(+id);
    }

    const newHandler = () => {
        navigate(generateLink(AdminLink.ADD_AREA));
    }

    const editModalHandler = (id: number | string) => {
        navigate(generateLink(AdminLink.EDIT_AREA, id));
    }

    const deleteHandlerYes = async () => {
        if (areas && actionId) {
            const isDelete = await deleteArea(actionId);
            if (isDelete) {
                await getAreas();
                setActionId(null);
                handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
            }
        }
    }

    const generateActions = () => {
        const actions: IAction[] = [
            {
                key: 'edit',
                actionHandler: editModalHandler,
                background: theme.colors.abbvie,
                actionElement: <EditIcon/>,
                width: 52,
            },
            {
                key: 'delete',
                actionHandler: deleteHandler,
                background: theme.colors.blue2,
                actionElement: <DeleteIcon/>,
                width: 52,
            }
        ];
        return actions;
    };

    const headCells: IHeadCell[] = [
        {id: 'shortTitle', numeric: false, label: 'Nazwa skrócona'},
        {id: 'fullTitle', numeric: false, label: 'Nazwa obszaru terapeutycznego (długa)'},
    ];

    const tableDataRows = createTableRows(areasStore.areas ?? [], headCells, false)

    return {
        isAreasLoaded,
        generateActions,
        headCells,
        newHandler,
        isModalOpened,
        closeModal,
        modalType,
        tableDataRows,
        actionId,
        deleteHandlerYes
    }
}