import styled from "styled-components";
import { Link } from "react-router-dom";
import { ButtonFontWeight, ButtonSize } from './Button';
import theme from "../../../theme/theme";

export const StyledLink = styled(Link)`
  text-decoration: none;
`
export const ButtonContainer = styled.button<{ $hoverColor?: string, $margin?: string, disabled?: boolean; size?: ButtonSize, $border?: string, color?: string, $backgroundColor?: string, $maxWidth?: string, $width?: string, $isIcon: boolean, fontSize?: string, fontWeight?: ButtonFontWeight }>`
  font-size: ${props => props.size === 'small' ? '16px' : (props.size === 'tiny' ? '12px' : '32px')};
  font-size: ${props => props.fontSize};
  height: ${props => props.size === 'small' || props.size === 'tiny' ? '38px' : '74px'};
  border-radius: 10px;
  color: ${props => props.color};
  background-color: ${props => props.disabled ? theme.colors.grey2 : props.$backgroundColor};
  text-transform: uppercase;
  font-weight: ${props => props.fontWeight || '400'};
  text-align: center;
  border: none;
  z-index: 150;
  position: relative;
  display: flex;
  letter-spacing: 1.2px;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  max-width: ${props => props.$maxWidth || "100%"};
  cursor: ${props => props.disabled ? 'normal' : 'pointer'};
  width: ${props => props.$width ? props.$width : "100%"};
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: ${props => props.$border ? `1px solid ${props.$border}` : 'none'};
  margin: ${props => props.$margin};

  &:hover, :active {
    background-color: ${props => props.$hoverColor && props.$hoverColor};
    opacity: ${props => props.$hoverColor ? 1 : 0.8};
  }

  & svg {
    right: 16px;
    vertical-align: middle;
  }
}
`
