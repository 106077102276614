import { RoleCategory, RoleFunction } from "../constants/projectUserRoles";

export enum GeneralLink {
  LOGIN = '/login',
  RETRIEVE_PASSWORD = '/retrieve-password',
  CHANGE_PASSWORD = '/reset-password/:token',
  REGISTER_VIEWER = '/register/:token',
  REGISTER_PUBLISH = '/register/publish/:token',
  REGISTER_ADMIN = '/register/admin/:token',
  HOME = '/',
  PAGE_404 = '*'
}

export enum AdminLink {
  REDIRECTOR = '/',
  ADD_USER = '/add-user',
  PUBLISHING_USERS = '/publishing-users',
  VIEWING_USERS = '/viewing-users',
  OTHERS_ADMINISTRATORS = '/other-administrators',
  OTHER = '/other',
  CASES = '/cases',
  HISTORY = '/cases/:id',
  VIEW_CASE = '/case/:id/view',
  FORMS = '/forms',
  AREAS = '/areas',
  EDIT_FORM= '/forms/:id',
  EDIT_AREA= '/areas/:id',
  ADD_FORM = '/forms/new',
  ADD_AREA = '/areas/new',
}

export enum UserLink {
  HOME = '/',
  ADD_CASE = '/case/add',
  EDIT_CASE = '/case/:id/edit',
  VIEW_CASE = '/case/:id/view',
  MY_CASES = '/cases',
  OTHER_CASES = '/cases/other',
  PROFILE = '/profile',
}


export const generateLink = (link: GeneralLink | AdminLink | UserLink, id?: number | string) => {
  return String(link).replace(":id", id ? id.toString() : "");
}

export const generateNavigationLink = (roleFunction: RoleFunction | null, roleCategory: RoleCategory): GeneralLink | AdminLink | UserLink => {
  let path: GeneralLink | AdminLink | UserLink = GeneralLink.LOGIN;
  if (roleCategory === RoleCategory.ADMIN) {
    if (RoleFunction.ROLE_ADMIN === roleFunction) {
      path = AdminLink.ADD_USER;
    }
    if (RoleFunction.ROLE_ACCEPTING === roleFunction || RoleFunction.ROLE_SCIENTIFIC === roleFunction) {
      path = AdminLink.CASES;
    }
  } else if (roleCategory === RoleCategory.USER) {
    path = UserLink.HOME;
  }

  return path;
}
