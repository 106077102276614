import {IRole} from '../../../../interfaces/common/roles';
import {IOthersAdmins} from '../../../../interfaces/admin/user/othersAdmins';
import {IOption} from '../../../../interfaces/common/form/option';
import {IAreas} from "../../../../interfaces/common/areas";

export const generateRoleOptions = (roles?: IRole[] | null, otherUsersList?: IOthersAdmins[] | null) => {
  const selectOptions: IOption[] = [];
  if (roles && otherUsersList) {

    otherUsersList.forEach(user => {
      const roleFind = roles.find(role => role.id === user.roleId);
      if (roleFind) {
        if (!selectOptions.find(option => option.id === roleFind.id)) {
          selectOptions.push({id: roleFind.id, title: roleFind.title});
        }
      }
    })
  }

  return selectOptions;
}

export const generateAreaOptions = (areas?: IAreas[] | null) => {
  const selectOptions: IOption[] = [];

  areas?.forEach(area => {
    selectOptions.push({id: area.id, title: area.fullTitle})
  })
  return selectOptions;
}